
import React from 'react'
import PropTypes from 'prop-types';
import {  Collapse, Button } from 'antd';
import {DribbbleOutlined, GithubOutlined, BankTwoTone, SlackOutlined  } from '@ant-design/icons';
import styled from 'styled-components'
import { Card, Col, Row } from 'antd';
import {Link} from 'react-router-dom'

const { Panel } = Collapse;
const ColoredCard  = styled(Card)`
background-image: linear-gradient(to bottom right, #0CBABA, #380036 );
height: 700px;


`


const Buttoned = styled(Button)`
background:transparent;
size:small;
@media(max-width: 720px){
  width: auto;
font-size: 9px;
font-weight: bold;
margin:0;
padding:0;

  }
`

const PanelColor = styled(Panel)`
box-sizing: content-box;
width: auto;
 box-sizing: 'border-box' ;
 background:transparent;
 @media(max-width: 720px){
width: auto;
font-size: 10px;
  }
`

 const Collapsed = styled(Collapse)`
width: auto;
 background:transparent;
 @media(max-width: 720px){
width: auto;
font-size: 10px;
margin: 0
  }

 `

const RowColor = styled(Row)`
margin:-1;
height: 300;
padding: -10px;
background: transparent
`
 
/* style={{height:1000, right:-1, top:-1 }} */



 const Banner0animate =()=> {

    return (
      <RowColor gutter={1}
    >
     
   
    <Col span={5}>
        
<ColoredCard bordered={true}
style={{ display:'flex', alignItems:'center', justifyContent:'center' , width: 'auto', boxSizing: 'border-box' }}

>

{/*drop down element for crypto currency*/}
<Collapsed 
expandIcon={({ isActive }) => <BankTwoTone  rotate={isActive ? 90 : 0} />}

>
<PanelColor header="xchange"  key="1" >
<Link to='/xchange'><Buttoned >crypto</Buttoned></Link>
<Link to='/xchange'><Buttoned>giftcard</Buttoned></Link>
<Link to='/xchange'><Buttoned>binary</Buttoned></Link>


</PanelColor>

</Collapsed>

</ColoredCard>

</Col>
    
    
 
<Col span={5}>
<ColoredCard  bordered={true}
style={{ display:'flex', alignItems:'center', justifyContent:'center', width: 'auto', boxSizing: 'border-box'}}>
{/*drop down element  for graphics*/}

<Collapsed

expandIcon={({ isActive }) => <DribbbleOutlined rotate={isActive ? 90 : 0} />}
>
<PanelColor header="graphix" key="2">
<Link to ='/graphics'><Buttoned>branding</Buttoned></Link>
<Link to ='/graphics'><Buttoned>animation</Buttoned></Link>
<Link to ='/graphics'><Buttoned> UI/UX</Buttoned></Link>
</PanelColor>


</Collapsed>

</ColoredCard>
</Col>


<Col span={5}>
<ColoredCard  bordered={true}
style={{ display:'flex', alignItems:'center', justifyContent:'center', width: 'auto', boxSizing: 'border-box'}}
>

{/*drop down element for wsitres */}
<Collapsed

expandIcon={({ isActive }) => <GithubOutlined rotate={isActive ? 90 : 0} />}
>
<PanelColor header="coding" key="3" >
<Link to ='/software'><Buttoned>web devop</Buttoned></Link>
<Link to ='/software'><Buttoned>app devop</Buttoned></Link>
<Link to ='/software'><Buttoned>cybersecurity</Buttoned></Link>
<Link to ='/software'><Buttoned>database</Buttoned></Link>

</PanelColor>

</Collapsed>
</ColoredCard>
</Col>

<Col span={5}>
<ColoredCard  bordered={true}
style={{ display:'flex', alignItems:'center', justifyContent:'center', width: 'auto'}}
>
{/*drop down element for engineering*/}

<Collapsed
expandIcon={({ isActive }) => <SlackOutlined rotate={isActive ? 90 : 0} />}
>
<PanelColor header="tech" key="4">
<Link to ='/engineering'><Buttoned>solar  </Buttoned></Link>
<Link to ='/engineering'><Buttoned>IoT</Buttoned></Link>
<Link to ='/engineering'><Buttoned> repairs</Buttoned></Link>
<Link to ='/engineering'><Buttoned>installs</Buttoned></Link>

</PanelColor>

</Collapsed>
</ColoredCard>
</Col>   

<Col span={2}>
<Card bordered={false} style={{ width:200, alignItems:'center', justifyContent:'center'}}>

<Row><Link to='/about'> about </ Link> </Row>
<Row><Link to ='/contact'> contact us</Link></Row>
<Row><Link to ='/blog'> blog</Link></Row>
</Card>
</Col>

</RowColor>
    )
    
  }

Banner0animate.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  paused: PropTypes.bool,
};
 export default Banner0animate