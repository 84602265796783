import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import BreakPointsUp from './fabtoggle/Fabtoggle'



const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      marginRight:' 0px',
      fontFamily: 'Arial, Helvetica, sans-serif',
      position: 'fixed',
      zIndex: 5,
right:    '0px',
bottom:   '0px',
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab:{
    height:40,
    width:40,
    color: 'white',
    backgroundImage:'linear-gradient(to right, #FFDD00, #FBB034)',
    opacity: 1 ,
  },
}));

export default function FloatingActionButtons() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fab className = {classes.fab}  aria-label="add">
        <BreakPointsUp/>
  
      </Fab>
      
              
    </div>
  );
}
