import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import Telegram from '@material-ui/icons/Telegram';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedIn from '@material-ui/icons/LinkedIn';
import WhatsApp from '@material-ui/icons/WhatsApp';




const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
  },
  typography: {
    padding: theme.spacing(2),
  },
  grid: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 200,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

export default function PositionedPopper() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const classes = useStyles();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  return (
    <div className={classes.root}>
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
          <div className= {classes.grid}>
          <Grid
  container
  direction="column"
  justify="center"
  alignItems="center"
>


<Grid item xs={12} sm={6}> <a href =' https://wa.me/message/7FQ35RMU2VVZP1' target="_blank" rel="noopener noreferrer"><WhatsApp fontSize= 'large'/></a> </Grid>
<Grid item xs={12} sm={6}>  <a href =' https://www.github.com/derekzyl' target="_blank" rel="noopener noreferrer"><GitHubIcon fontSize= 'large'/></a> </Grid>       
<Grid item xs={12} sm={6}>  <a href =' https://t.me/derekzyl' target="_blank" rel="noopener noreferrer"><Telegram fontSize= ' large'/></a></Grid>
<Grid item xs={12} sm={6}> <a href =' https://www.linkedin.com/in/derekzyl' target="_blank" rel="noopener noreferrer"><LinkedIn fontSize= 'large'/></a></Grid>
         
        

</Grid>
          
          </div>
          </Fade>
        )}
      </Popper>
      <Grid container justify="center">
        <Grid item>
        <ChatBubbleIcon 
         onClick={handleClick('top')}/>
        </Grid>
      </Grid>
    
    
    </div>
  );
}







