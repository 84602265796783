import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Paper from '@material-ui/core/paper'
import styled from 'styled-components'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SimpleRating from './Review'

import a from '../images/partner/1.png'
import b from '../images/partner/2.png'
import c from '../images/partner/3.png'
import d from '../images/partner/4.png'
import e from '../images/partner/5.png'
import f from '../images/partner/6.png'
import g from '../images/partner/21.png'
import h from '../images/partner/22.png'
import i from '../images/partner/23.png'
import j from '../images/partner/24.png'
import k from '../images/partner/Intel.png'
import l from '../images/partner/broadcom.png'
import m from '../images/partner/fujitsu.png'
import n from '../images/partner/Analog_Devices01.png'
import o from '../images/partner/nxp.png'
import p from '../images/partner/qualcomm.png'
import q from '../images/partner/marvell.png'
import r from '../images/partner/maxlianear.png'
import s from '../images/partner/st.png'
import t from '../images/partner/realtek.png'
import u from '../images/partner/siliconlabs.png'
import v from '../images/partner/TexasInstruments-Logo01.png'


const data =[
    {id:1, name:a},
    {id:2, name:b},
    {id:3, name:c},
    {id:4, name:d},
    {id:5, name:e},
    {id:6, name:f},
    {id:7, name:g},
    {id:8, name:h},
    {id:9, name:i},
    {id:10, name:j},
    {id:11, name:k},
    {id:12, name:l},
    {id:13, name:m},
    {id:14, name:n},
    {id:15, name:o},
    {id:16, name:p},
    {id:17, name:q},
    {id:18, name:r},
    {id:19, name:s},
    {id:20, name:t},
    {id:21, name:u},
    {id:22, name:v}

]

    const test =[
        {id:1, comment:" created a perfect, seamless integration with every feature we requested", name:'MICHAEL DONALDS' },
        {id:2, comment:"There were no gray areas or misunderstandings because they were honest with me.", name:'OLUSOLA DAVIDS'},
        {id:3, comment:"Their team has a deep skill set that covers all our engineering needs.", name:'ADELEKE MICHAEL'},
        {id:4, comment:"They proved their technical expertise and project experience from the get-go.", name:'JORDAN HARRIS'},
        {id:5, comment:" delivered everything on time and communicated payments clearly, so it has been a positive experience", name:'ABRAM ZIK' },
        {id:6, comment:"Communication was huge for me and I was very impressed with how they handled themselves.", name: 'HARRIS DAVIS'},
        {id:7, comment:"Their dedication and willingness to help were impressive. They were efficient and kept everyone on task.", name:'ANDREA PALIN'},
        {id:8, comment:"Their understanding of our business’ needs is valuable to us.", name:'MICHELLE GRIM'},
        {id:9, comment:"I've been impressed by their willingness to do right by us and our customers.", name: 'SAVIOR DAVIS'},
        {id:10, comment:"The website they developed is fully-functional, powerful, and easy-to-use.", name:'ORLANDO LUCIA'},
        {id:11, comment:  "I'm always impressed by how well they understand our clients' needs and how their team adjusts accordingly." , name:'PAUL SEGUN' },
        {id:12, comment:'They have truly earned this 5 star review. I have worked with many developers over the years and  has been the best by far', name:'ANDERSON MILLER' },
        {id:13, comment:' I can honestly say that  has an impressive arsenal of talent that is ready to take your development needs to the next level.', name:'IRENE MAVIS'},
        {id:14, comment:' They were worth every dollar we invested in the project and look forward to a long working relationship with ', name:'VICTORIA CAMPBELL'},   
        {id:15, comment:' I would highly recommend  if you want your dreams to come true. I had envisioned an automated process over 10 years ago and  was able to make that dream come true.', name:'CHIBUZOR DAVID'},
        {id:16, comment: ' The team is knowledgeable and professional at both a managerial and technical level.  is very transparent and accessible, and they make communication easy',name:'ZUKER GREEN'}
    
    ]


 const Div = styled.div`
 display: flex;
 flex-wrap: wrap;
padding: 1rem
 `


const P = styled.div`
font-size: 3rem;
font-weight:bold;

@media(max-width: 720px){
font-size: 1.5rem;
font-weight:bold;


} 

    

`

 export  class Car extends React.Component{



render(){

  
    var settings1 = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 6000,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        pauseOnHover: true
       
      };

        
    var settings2 = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 6000,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        pauseOnHover: true
       
      };

    const VideoSrc = (width)=>{
        if (width > 700){
            return settings1 }
        else {
            return settings2
        }
      
       
    }
 const settings = VideoSrc(window.innerWidth)
    
  
    return (

        <div >
        <P>ACTIVE PATNERS</P>
            <Slider {...settings}>
                
             {data.map((user)=>(
                 <Div key={user.id}>
                     <Paper elevation={3}>
                     <img alt ='altered' src ={user.name}/>
                     </Paper>
                 </Div>
             ))} 
            </Slider>
            </div>
        );
    }

   
      
        }



export class Testimonies extends React.Component{
    render(){
        
    var settings1 = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        pauseOnHover: true
       
      };

        
    var settings2 = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        pauseOnHover: true
       
      };

    const VideoSrc = (width)=>{
        if (width > 700){
            return settings1 }
        else {
            return settings2
        }
      
       
    }
 const settings = VideoSrc(window.innerWidth)
        return(
<div>
<P>Testimonies</P>
<Slider {...settings}>
{test.map((user)=>(
                 <Div key={user.id}>
                     <Paper elevation={3}>
                <h4>"{user.comment}"</h4>
                <h6><i>{user.name}</i></h6>
                     <SimpleRating/>
                     </Paper>
                 </Div>
             ))} 
             </Slider>

                    

</div>
        )
    }
}
