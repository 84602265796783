import React from 'react';
import {What}  from './textScript/textScript'
import moment from 'moment'
import logo from '../images/logo.png'
import xchange from '../images/XCHANGE.png'
import graphics from '../images/GRAPHICS.png'
import coding from '../images/CODING.png'
import engine from '../images/ENGINNERING.png'
import blog from '../images/blog.png'
import digital from '../images/MARKET.png'
import aa from '../images/a.jpg'
import bb from '../images/b.jpg'
import cc from '../images/c.jpg'
import dd from '../images/d.jpg'
import ee from '../images/e.jpg'
import ff from '../images/f.jpg'

import {Link} from 'react-router-dom'









export const Banner00DataSource = {
 
  wrapper: { className: 'banner0 kceo217ocdi-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper' },
  draw: { className:'banner0-draw'},
    drawer:{ className:'drawer'},
  
 

  
  title:{
    className: 'banner0-title',
    children: <img  src= {logo} alt='logo'/>,
    
  },
  content: {
    className: 'banner0-content kceo00knvlt-editor_css',
    children: (
      <span>
        <p>
       
        </p>
      </span>
    ),
  },
  button: {
    className: 'banner0-button kceo0apni4r-editor_css',
    children: (
      <span style={{alignContent:"center"}}>
   <a
                      href="codingcircled.blogspot.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      
                      <Link to ="/about">
                      <b>portfolio</b>
        </Link>
                    </a>
      </span>
    ),
  },
};






// corousel 



export const Banner10DataSource ={


  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg0 kceoi1j9t1q-editor_css' },
        title: {
          className: 'banner1-title',
          children:
          <img  src= {graphics} alt='graphics'/>,
        },
        content: {
          className: 'banner1-content',
          children: '',
        },
        button: { className: 'banner1-button',
         children: 
         <Link to ="/graphics">
         Learn More
         </Link> },
      },

      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
        title: {
          className: 'banner1-title',
          children:
          <img  src= {engine} alt='engine'/>,
          
        },
        content: {
          className: 'banner1-content',
          children: ' ',
        },
      
        button: { className: 'banner1-button', children:
        <Link to ="/engineering">
        Learn More
        </Link> },  
        
      },

      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg2' },
        title: {
          className: 'banner1-title',
          children:
          <img  src= {coding} alt='coding'/>,

        },
        content: {
          className: 'banner1-content',
          children: '',
        },
        button: { className: 'banner1-button', children: 
        <Link to ="/software">
        Learn More
        </Link> },
      },

      {
        name: 'elem~kceoh9k7lan',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg3' },
        title: {
          className: 'banner1-title',
          children:
          <img  src= {xchange} alt='xchange'/>,

        },
        content: {
          className: 'banner1-content',
          children: '',
        },
        button: { className: 'banner1-button', children: 
        <Link to ="/xchange">
        Learn More
        </Link> },
      },
      
      {
        name: 'elem~kceohb7bf1d',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg4' },
        title: {
          className: 'banner1-title',
          children:
          <img  src= {blog} alt='blog'/>,
          
        },
        content: {
          className: 'banner1-content',
          children: '',
        },
        button: { className: 'banner1-button', children: 
        <Link to ="/blog">
        Learn More
        </Link> },
      },
      {
        name: 'elem~kceohclglrr',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg5' },
        title: {
          className: 'banner1-title',
          children:
          <img  src= {xchange} alt='xchange'/>,

        },
        content: {
          className: 'banner1-content',
          children: '',
        },
        button: { className: 'banner1-button', children:
        <Link to ="/xchange">
        Learn More
        </Link> },
      },
      {
        name: 'elem~kceohezrdjl',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
        title: {
          className: 'banner1-title',
          children:
          <img  src= {digital} alt='DIGITAL'/>,
          
        },
        content: {
          className: 'banner1-content',
          children: '',
        },
        button: { className: 'banner1-button', children: 
        <Link to ="/digital">
        Learn More
        </Link> },
      },
    ],
  },
};














export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5 kceomxim1t6-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [

 { name: 'title', children:  <What/>, className: 'title-h1 ' },
      {
        name: 'content',
        className: 'title-content',
        children: '',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs:12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img:{
            children: 
            aa ,
          },
          content: { children:<Link to ='/graphics'> <img src={graphics} alt="graphics"/></Link> },
        },
      },
     

      
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
            ff,
          },
          content: { children:<Link to= '/software'> <img src={coding} alt="coding"/></Link> },
        },
      },
     
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
            cc,
          },
          content: { children:<Link to ='/blog'> <img src={blog} alt="blog"/> </Link> },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
             dd,
          },
        content: {children:<Link to ='/xchange'> <img src={xchange} alt="xchange"/></Link>,}
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              ee
          },
          content: { children:<Link> <img src={digital} alt="digital"/></Link> },
        },
      },
    
      

      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 12,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:bb,
          },
          content: { children:<Link to ='/engineering'><img src={engine} alt="engine"/></Link>  },
        },
      },
    ],
  },
};








export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page kceopf87l2-editor_css',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 6,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            <img src = {logo} alt ='logo'/>,
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: 'a DEREK OG website',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 6,
        md: 6,
        className: 'block',
        title: { className:'black',
          children: 'WHAT WE DO' },
        childWrapper: {
          children: [
            { name: 'link0', to: '/graphics', children: 'GRAPHICS' },
            { name: 'link1', to: 'xchange', children: 'XCHANGE' },
            { name: 'link2', to: '/engineering', children: 'ENGINEERING' },
            { name: 'link3', to: '/software', children: 'CODING' },
            { name: 'link4', to: '/digital', children: 'MARKETING' },


          ],
        },
      },
      {
        name: 'block2',
        xs: 6,
        md: 6,
        className: 'block',
        title: {
          className:'black',
           children: 'CALL' },
        childWrapper: {
          children: [
            { to: 'about', name: 'link0', children: 'FAQ' },
            { to: '+2348188266175', name: 'link7', children: '+2348188266175' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 6,
        md: 6,
        className: 'block',
        title: {
          className:'black',
           children: 'ABOUT US' },
        childWrapper: {
          children: [
            { to: '/blog', name: 'link0', children: 'blog' },
            { to: '/software', name: 'link1', children: 'web design' },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©{moment().format('DD-MM-YYYY')} by <Link to="/">CyberGenii</Link> All Rights
        Reserved
      </span>
    ),
  },
};
