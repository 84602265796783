import React from 'react';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import styled from 'styled-components'
const P = styled.p`
font-size:8px
`
export default function SimpleRating() {
 

  return (
    <div>
      
      <Box component="fieldset" mb={3} borderColor="transparent">
        <P component="legend">client review</P>
        <Rating name="read-only" value={5} readOnly />
      </Box>
     
    </div>
  );
}
